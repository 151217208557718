import Breadcrumb from "../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { Card, Col, Row } from "reactstrap";

import { PuffLoader } from "react-spinners";
import moment from "moment";
import { DataGenerator } from "../../components/DtaGenerator";

function getId() {
  const temp = window.location.pathname.split("/");
  return temp[temp.length - 1];
}

export function User() {
  const ID = getId();
  const [user, setUser] = useState();

  const getUsers = () => {
    setUser();
    Axios.get(`/admin/user/${ID}`).then((res) => {
      setUser(res.data.result.user);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="page-content">
      <Card className="p-3">
        <Breadcrumb title="User" />
        {!user ? (
          <div className="d-flex justify-content-center p-5">
            <PuffLoader size={30} />
          </div>
        ) : (
          <Row>
            <DataGenerator title="Phone Number" desc={user?.phone_number} />
            <DataGenerator title="Freeze" desc={user?.freeze ? "YES" : "NO"} />
            <DataGenerator
              title="Date"
              desc={moment(user?.date).format("YYYY/MM/DD - HH:mm")}
            />
          </Row>
        )}
      </Card>
    </div>
  );
}
