import { Col, Row } from "reactstrap";
import React from "react";
import { useCallback } from "react";
import Axios from "axios";

export const DataGenerator = ({ title, desc }) => (
  <Col key={title} xs={12} md={6} lg={3}>
    <div className="d-flex flex-column p-1 border-bottom-1">
      <p className="font-weight-bold text-muted mb-2">{title}</p>
      {typeof desc === "string" ? <p className="">{desc ?? " - "}</p> : desc}
    </div>
  </Col>
);
