import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard";
import { Users, User } from "../pages/users";
import { Orders, Order } from "../pages/orders";
import { Transactions, Transaction } from "../pages/transactions";
import { Accounts, Account } from "../pages/accounts";
import Settings from "../pages/Settings";
import { Wallets } from "../pages/wallets/Wallets";
import { ManualFund } from "../pages/manual-fund/ManualFund";
import { Compliance } from "../pages/compliance";
const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/settings", component: Settings },
  { path: "/users/:id", component: User },
  { path: "/users", component: Users },
  { path: "/orders/:id", component: Order },
  { path: "/orders", component: Orders },
  { path: "/compliance", component: Compliance },
  { path: "/accounts/:id", component: Account },
  { path: "/accounts", component: Accounts },
  { path: "/transactions/:id", component: Transaction },
  { path: "/transactions", component: Transactions },
  { path: "/wallets", component: Wallets },
  { path: "/manual-fund", component: ManualFund },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
