import Breadcrumb from "../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { Card, Col, Row } from "reactstrap";

import { PuffLoader } from "react-spinners";
import moment from "moment";
import { DataGenerator } from "../../components/DtaGenerator";
import { getStatus } from "../../components/utils";
import { Link } from "react-router-dom";

function getId() {
  const temp = window.location.pathname.split("/");
  return temp[temp.length - 1];
}

export function Transaction() {
  const ID = getId();
  const [data, setData] = useState();

  const getData = () => {
    setData();
    Axios.get(`/admin/transaction/${ID}`).then((res) => {
      setData(res.data.result.transaction);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="page-content">
      <Card className="p-3">
        <Breadcrumb title="Transaction" />
        {!data ? (
          <div className="d-flex justify-content-center p-5">
            <PuffLoader size={30} />
          </div>
        ) : (
          <Row>
            <DataGenerator title="Wallet Address" desc={data?.address} />
            <DataGenerator
              title="Date"
              desc={moment(data?.date).format("YYYY/MM/DD - HH:mm")}
            />
            <DataGenerator title="Coin" desc={data.coin ?? " - "} />
            <DataGenerator
              title="Amount"
              desc={String(data.amount.toFixed(8))}
            />

            <DataGenerator
              title="TXID"
              desc={
                data.txid ? (
                  <a
                    href={`https://www.blockchain.com/explorer/search?search=${data.txid}`}
                  >
                    {data.txid}
                  </a>
                ) : (
                  " - "
                )
              }
            />
          </Row>
        )}
      </Card>
    </div>
  );
}
