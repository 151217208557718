import Breadcrumb from "../../components/Common/Breadcrumb";
import toast from "../../components/toast";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { Alert, ButtonGroup, Card, Table } from "reactstrap";
import { PuffLoader } from "react-spinners";
import moment from "moment";
import { Link } from "react-router-dom";
import { truncate } from "../../components/utils";
import { useCountDown } from "../../components/useCountDown";

const LIMIT = 100;
export function Transactions() {
  const [data, setData] = useState();
  const [totalCount, setTotalCount] = useState();
  const [offset, setOffset] = useState(0);
  const [addressParam, setAddressParam] = useState("");
  const { timeLeft, reset } = useCountDown(60);
  const shouldStartTimer = useRef(false);

  const getData = () => {
    setData();
    Axios.get(
      `/admin/transactions?limit=${LIMIT}&offset=${offset}${
        addressParam?.length ? "&address=" + addressParam : ""
      }`
    ).then((res) => {
      reset();
      shouldStartTimer.current = true;
      setData(res.data.result.result);
      setTotalCount(res.data.result_info.info.total_count);
    });
  };

  useEffect(() => {
    getData();
  }, [offset]);

  useEffect(() => {
    if (timeLeft === 0 && shouldStartTimer.current === true) {
      getData();
    }
  }, [timeLeft]);

  return (
    <div className="page-content">
      {!!timeLeft && shouldStartTimer.current === true && (
        <Alert color="info">Data will update in {timeLeft} seconds</Alert>
      )}
      <Card className="p-3">
        <Breadcrumb
          title={`Transactions ${totalCount ? `(${totalCount})` : ""}`}
        />
        {!data ? (
          <div className="d-flex justify-content-center p-5">
            <PuffLoader size={100} />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-start mb-2 align-items-center">
              <input
                style={{ maxWidth: 250 }}
                className="form-control my-2 mx-1"
                placeholder="wallet address..."
                value={addressParam}
                onChange={(e) => setAddressParam(e.target.value)}
              />
              <button
                onClick={() => {
                  offset === 0 ? getData() : setOffset(0);
                }}
                className="btn btn-outline-primary "
              >
                Search
              </button>
            </div>
            <Table>
              <thead>
                <tr>
                  <td>ID</td>

                  <td>Date</td>
                  <td>Wallet Address</td>
                  <td>Amount</td>
                  <td>TXID</td>
                </tr>
              </thead>
              <tbody>
                {data?.map((i) => (
                  <tr>
                    <td>
                      <Link to={`/transactions/${i._id}`}>
                        {truncate(i._id)}
                      </Link>
                    </td>
                    <td>{moment(i.date).format("YYYY/MM/DD - HH:mm")}</td>
                    <td>{truncate(i.address)}</td>

                    <td>{i.amount ? i.amount.toFixed(8) : " - "}</td>

                    <td>
                      {!i.txid ? (
                        " - "
                      ) : (
                        <a
                          href={`https://www.blockchain.com/explorer/search?search=${i.txid}`}
                        >
                          {truncate(i.txid)}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <ButtonGroup style={{ maxWidth: 300 }} className="mx-auto">
              <button
                className="btn btn-sm btn-outline-secondary"
                disabled={offset <= 0}
                onClick={() => setOffset((prev) => prev - LIMIT)}
              >
                PREVIOUS
              </button>

              <button
                disabled={offset + LIMIT >= totalCount}
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setOffset((prev) => prev + LIMIT)}
              >
                NEXT
              </button>
            </ButtonGroup>
          </>
        )}
      </Card>
    </div>
  );
}
