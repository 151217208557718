import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";

// Pages Components
//i18n

const Dashboard = (props) => {
  //   const reports = [
  //         { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
  //         { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
  //         { title: "Average Price", iconClass: "bx-purchase-tag-alt", description: "$16.2" }
  //     ];
  //  const email = [
  //         { title: "Week", linkto: "#", isActive: false },
  //         { title: "Month", linkto: "#", isActive: false },
  //         { title: "Year", linkto: "#", isActive: true }
  //     ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <Row>
                {/* {
                                        reports.map((report, key) =>
                                            <Col md="4" key={"_col_" + key}>
                                                <Card className="mini-stats-wid">
                                                    <CardBody>
                                                        <Media>
                                                            <Media body>
                                                                <p className="text-muted font-weight-medium">{report.title}</p>
                                                                <h4 className="mb-0">{report.description}</h4>
                                                            </Media>
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                                <span className="avatar-title">
                                                                    <i className={"bx " + report.iconClass + " font-size-24"}></i>
                                                                </span>
                                                            </div>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    } */}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
