import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const toast = (type,message) =>{
    toastr.options = {
        positionClass : "toast-bottom-left",
        timeOut: 2000,
        extendedTimeOut : 2000,
        closeButton : true,
        progressBar : true,
        preventDuplicates : false,
        newestOnTop : true,
    }
    if(type === "success") toastr.success(message)
    if(type === "error") toastr.error(message)
}

export default toast