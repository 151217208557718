import Breadcrumb from "../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { Card, Col, Row } from "reactstrap";

import { PuffLoader } from "react-spinners";
import moment from "moment";
import { DataGenerator } from "../../components/DtaGenerator";
import { getStatus } from "../../components/utils";
import { Link } from "react-router-dom";

function getId() {
  const temp = window.location.pathname.split("/");
  return temp[temp.length - 1];
}

export function Account() {
  const ID = getId();
  const [data, setData] = useState();

  const getData = () => {
    setData();
    Axios.get(`/admin/account/${ID}`).then((res) => {
      setData(res.data.result.account);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="page-content">
      <Card className="p-3">
        <Breadcrumb title="Account" />
        {!data ? (
          <div className="d-flex justify-content-center p-5">
            <PuffLoader size={30} />
          </div>
        ) : (
          <Row>
            <DataGenerator title="Name" desc={data?.accountName} />
            <DataGenerator title="Account Number" desc={data?.accountNumber} />
            <DataGenerator title="Bank Name" desc={data?.bankName} />
            <DataGenerator title="Bank Code" desc={data?.bankCode} />
            <DataGenerator title="Currency" desc={data?.currency} />
          </Row>
        )}
      </Card>
    </div>
  );
}
