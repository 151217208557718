import Breadcrumb from "../../components/Common/Breadcrumb";
import toast from "../../components/toast";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { Alert, ButtonGroup, Card, Table } from "reactstrap";
import { PuffLoader } from "react-spinners";
import moment from "moment";
import { Link } from "react-router-dom";
import { truncate, getStatus, serializeParams } from "../../components/utils";
import { useCountDown } from "../../components/useCountDown";

const CANCELED_STATUS = "CANCELED BY HAND";
const LIMIT = 100;
export function Compliance() {
  const [data, setData] = useState();
  const [totalCount, setTotalCount] = useState();
  const [offset, setOffset] = useState(0);
  const [addressParam, setAddressParam] = useState("");
  const [statusParam, setStatusParam] = useState("");
  const [indexParam, setIndexParam] = useState("");
  const [phoneNumberParam, setPhoneNumberParam] = useState("");
  const { timeLeft, reset } = useCountDown(60);
  const shouldStartTimer = useRef(false);

  const getData = () => {
    setData();
    Axios.get(
      `/admin/payments?${!!statusParam ? "&" + statusParam + "=true" : ""}`,
      {
        params: {
          limit: LIMIT,
          offset,
          index: indexParam.length ? indexParam : undefined,
          address: addressParam.length ? addressParam : undefined,
          phone_number: phoneNumberParam.length ? phoneNumberParam : undefined,
        },
      }
    )
      .then((res) => {
        reset();
        shouldStartTimer.current = true;
        setData(res.data.result.result);
        setTotalCount(res.data.result_info.info.total_count);
      })
      .catch(() => {
        reset();
      });
  };

  useEffect(() => {
    getData();
  }, [offset]);

  useEffect(() => {
    if (timeLeft === 0 && shouldStartTimer.current === true) {
      getData();
    }
  }, [timeLeft]);

  const handleCancel = (id) => {
    Axios.post(`/admin/payment/cancel/${id}`)
      .then(() => {
        toast("success", "cancelled successfully");
        getData();
      })
      .catch(() => {
        toast("error", "something happened");
      });
  };

  return (
    <div className="page-content">
      {!!timeLeft && shouldStartTimer.current === true && (
        <Alert color="info">Data will update in {timeLeft} seconds</Alert>
      )}
      <Card className="p-3">
        <Breadcrumb title={`Orders ${totalCount ? `(${totalCount})` : ""}`} />
        {!data ? (
          <div className="d-flex justify-content-center p-5">
            <PuffLoader size={100} />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-start mb-2 align-items-center">
              <select
                value={statusParam}
                onChange={(e) => setStatusParam(e.target.value)}
                className="form-control ml-2"
                style={{ maxWidth: 250 }}
              >
                <option value="">Status</option>
                <option value="imported">imported</option>
                <option value="memepool">memepool</option>
                <option value="ready">ready</option>
                <option value="close">close</option>
                <option value="funded">funded</option>
              </select>
              <input
                style={{ maxWidth: 250 }}
                className="form-control my-2 mx-1"
                placeholder="wallet address..."
                value={addressParam}
                onChange={(e) => setAddressParam(e.target.value)}
              />
              <input
                style={{ maxWidth: 150 }}
                className="form-control my-2 mx-1"
                placeholder="Index..."
                value={indexParam}
                onChange={(e) => setIndexParam(e.target.value)}
              />
              <input
                style={{ maxWidth: 150 }}
                className="form-control my-2 mx-1"
                placeholder="Phone number..."
                value={phoneNumberParam}
                onChange={(e) => setPhoneNumberParam(e.target.value)}
              />
              <button
                onClick={() => {
                  offset === 0 ? getData() : setOffset(0);
                }}
                className="btn btn-outline-primary "
              >
                Search
              </button>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <td>Order ID</td>
                  <td>ID</td>
                  <td>Date</td>
                  <td>Wallet Address</td>
                  <td>Coin</td>
                  <td>Value received</td>
                  <td>Phone number</td>
                  <td>Amount($)</td>
                  <td>Status</td>
                  <td>MANAGE</td>
                </tr>
              </thead>
              <tbody>
                {data?.map((i) => (
                  <tr>
                    <td>{i.index}</td>
                    <td>
                      <Link to={`/orders/${i._id}`}>{truncate(i._id)}</Link>
                    </td>
                    <td>{moment(i.date).format("YYYY/MM/DD - HH:mm")}</td>
                    <td>{truncate(i.address)}</td>
                    <td>{i.coin}</td>
                    <td>{i.coin_amount ? i.coin_amount.toFixed(8) : " - "}</td>

                    <td>{i.phone_number ?? " - "}</td>

                    <td>{!i?.usd_amount ? 0 : i?.usd_amount.toFixed(2)}</td>

                    <td>
                      {i?.bank_txid === CANCELED_STATUS
                        ? "CANCELED"
                        : "RUNNING"}
                    </td>
                    <td>
                      {i?.bank_txid !== CANCELED_STATUS && (
                        <button
                          onClick={() => handleCancel(i._id)}
                          className="btn btn-sm btn-outline-danger"
                        >
                          CANCEL
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <ButtonGroup style={{ maxWidth: 300 }} className="mx-auto">
              <button
                className="btn btn-sm btn-outline-secondary"
                disabled={offset <= 0}
                onClick={() => setOffset((prev) => prev - LIMIT)}
              >
                PREVIOUS
              </button>

              <button
                disabled={offset + LIMIT >= totalCount}
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setOffset((prev) => prev + LIMIT)}
              >
                NEXT
              </button>
            </ButtonGroup>
          </>
        )}
      </Card>
    </div>
  );
}
