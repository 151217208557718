export const truncate = (str) => {
  if (str) {
    return str.substring(0, 10) + ` ...`;
  }
  return '';
};
export const getStatus = (item) => {
  if (item) {
    const { processed, imported, close, ready, funded } = item;

    if (funded) return "FUNDED";
    if (ready) return "READY";
    if (close) return "CLOSE";
    if (processed) return "PROCESSED";
    if (imported) return "IMPORTED";
  }
};

export function serializeParams(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
