import Breadcrumb from "../../components/Common/Breadcrumb";
import toast from "../../components/toast";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { Alert, ButtonGroup, Card, Table } from "reactstrap";
import { useCallback } from "react";
import { PuffLoader } from "react-spinners";
import moment from "moment";
import { Link } from "react-router-dom";
import { useCountDown } from "../../components/useCountDown";

const LIMIT = 100;
export function Users() {
  const [users, setUsers] = useState();
  const [totalCount, setTotalCount] = useState();
  const [offset, setOffset] = useState(0);
  const [freezeLoading, setFreezeLoading] = useState(null);
  const [phoneParam, setPhoneParam] = useState("");
  const { timeLeft, reset } = useCountDown(60);
  const shouldStartTimer = useRef(false);

  const getUsers = () => {
    setUsers();
    Axios.get(
      `/admin/users?limit=${LIMIT}&offset=${offset}${
        phoneParam?.length ? "&phone_number=" + phoneParam : ""
      }`
    ).then((res) => {
      reset();
      shouldStartTimer.current = true;
      setUsers(res.data.result.result);
      setTotalCount(res.data.result_info.info.total_count);
    });
  };

  useEffect(() => {
    getUsers();
  }, [offset]);

  useEffect(() => {
    if (timeLeft === 0 && shouldStartTimer.current === true) {
      getUsers();
    }
  }, [timeLeft]);

  const handleFreeze = useCallback((id) => {
    setFreezeLoading(id);
    Axios.post(`/admin/user/freeze/${id}`)
      .then(() => {
        setFreezeLoading(null);
        toast("success", "Operation as successfull");
        getUsers();
      })
      .catch(() => {
        toast("error", "Operation Failed");
        setFreezeLoading(null);
      });
  }, []);
  return (
    <div className="page-content">
      {!!timeLeft && shouldStartTimer.current === true && (
        <Alert color="info">Data will update in {timeLeft} seconds</Alert>
      )}
      <Card className="p-3">
        <Breadcrumb title={`Users ${totalCount ? `(${totalCount})` : ""}`} />
        {!users ? (
          <div className="d-flex justify-content-center p-5">
            <PuffLoader size={100} />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-start mb-2 align-items-center">
              <input
                style={{ maxWidth: 300 }}
                className="form-control my-2 mx-1"
                placeholder="mobile number..."
                value={phoneParam}
                onChange={(e) => setPhoneParam(e.target.value)}
              />
              <button
                onClick={() => {
                  offset === 0 ? getUsers() : setOffset(0);
                }}
                className="btn btn-outline-primary "
              >
                Search
              </button>
            </div>
            <Table>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Date</td>
                  <td>Phone Number</td>
                  <td>Suspend</td>
                </tr>
              </thead>
              <tbody>
                {users?.map((i) => (
                  <tr>
                    <td>
                      <Link to={`/users/${i._id}`}>{i._id}</Link>
                    </td>
                    <td>{moment(i.date).format("YYYY/MM/DD - HH:mm")}</td>
                    <td>{i.phone_number}</td>
                    <td>
                      <button
                        disabled={freezeLoading === i._id}
                        onClick={() => handleFreeze(i._id)}
                        className={`btn btn-sm btn-outline-${
                          i.freeze ? "danger" : "primary"
                        }`}
                      >
                        {freezeLoading
                          ? "Loading..."
                          : i.freeze
                          ? "Unsuspend"
                          : "Suspend"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <ButtonGroup style={{ maxWidth: 300 }} className="mx-auto">
              <button
                className="btn btn-sm btn-outline-secondary"
                disabled={offset <= 0}
                onClick={() => setOffset((prev) => prev - LIMIT)}
              >
                PREVIOUS
              </button>

              <button
                disabled={offset + LIMIT >= totalCount}
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setOffset((prev) => prev + LIMIT)}
              >
                NEXT
              </button>
            </ButtonGroup>
          </>
        )}
      </Card>
    </div>
  );
}
