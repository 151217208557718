import React from "react";

import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
// Import scss
import "./assets/scss/theme.scss";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import axios from "axios";
const App = (props) => {
  axios.defaults.baseURL = "https://api.bitmessa.com";
  axios.interceptors.response.use(
    (res) => res,
    function (res) {
      if (res.response?.status === 403) {
        if (res.response.data.code === 100) {
          localStorage.removeItem("authUser");
          if (!window.location.href.includes("/login")) {
            window.location.href = "/login";
          }
        }
      }
      return Promise.reject(res);
    }
  );
  axios.interceptors.request.use(
    function (config) {
      config.headers.dashagh = localStorage.getItem("authUser");
      config.withCredentials = true;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  function getLayout() {
    return VerticalLayout;
  }

  const Layout = getLayout();

  const NonAuthmiddleware = ({ component: Component, layout: Layout }) => (
    <Route
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
