import Breadcrumb from "../../components/Common/Breadcrumb";
import toast from "../../components/toast";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { Alert, ButtonGroup, Card, Table } from "reactstrap";
import { PuffLoader } from "react-spinners";
import moment from "moment";
import { Link } from "react-router-dom";
import { truncate, getStatus, serializeParams } from "../../components/utils";
import { useCountDown } from "../../components/useCountDown";

const LIMIT = 100;
export function Orders() {
  const [data, setData] = useState();
  const [totalCount, setTotalCount] = useState();
  const [offset, setOffset] = useState(0);
  const [freezeLoading, setFreezeLoading] = useState(null);
  const [addressParam, setAddressParam] = useState("");
  const [statusParam, setStatusParam] = useState("");
  const [indexParam, setIndexParam] = useState("");
  const [phoneNumberParam, setPhoneNumberParam] = useState("");
  const { timeLeft, reset } = useCountDown(60);
  const shouldStartTimer = useRef(false);

  const getData = () => {
    setData();
    Axios.get(
      `/admin/payments?${!!statusParam ? "&" + statusParam + "=true" : ""}`,
      {
        params: {
          limit: LIMIT,
          offset,
          index: indexParam.length ? indexParam : undefined,
          address: addressParam.length ? addressParam : undefined,
          phone_number: phoneNumberParam.length ? phoneNumberParam : undefined,
        },
      }
    )
      .then((res) => {
        reset();
        shouldStartTimer.current = true;
        setData(res.data.result.result);
        setTotalCount(res.data.result_info.info.total_count);
      })
      .catch(() => {
        reset();
      });
  };

  useEffect(() => {
    getData();
  }, [offset]);

  useEffect(() => {
    if (timeLeft === 0 && shouldStartTimer.current === true) {
      getData();
    }
  }, [timeLeft]);

  return (
    <div className="page-content">
      {!!timeLeft && shouldStartTimer.current === true && (
        <Alert color="info">Data will update in {timeLeft} seconds</Alert>
      )}
      <Card className="p-3">
        <Breadcrumb title={`Orders ${totalCount ? `(${totalCount})` : ""}`} />
        {!data ? (
          <div className="d-flex justify-content-center p-5">
            <PuffLoader size={100} />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-start mb-2 align-items-center">
              <select
                value={statusParam}
                onChange={(e) => setStatusParam(e.target.value)}
                className="form-control ml-2"
                style={{ maxWidth: 250 }}
              >
                <option value="">Status</option>
                <option value="imported">imported</option>
                <option value="memepool">memepool</option>
                <option value="ready">ready</option>
                <option value="close">close</option>
                <option value="funded">funded</option>
              </select>
              <input
                style={{ maxWidth: 250 }}
                className="form-control my-2 mx-1"
                placeholder="wallet address..."
                value={addressParam}
                onChange={(e) => setAddressParam(e.target.value)}
              />
              <input
                style={{ maxWidth: 150 }}
                className="form-control my-2 mx-1"
                placeholder="Index..."
                value={indexParam}
                onChange={(e) => setIndexParam(e.target.value)}
              />
              <input
                style={{ maxWidth: 150 }}
                className="form-control my-2 mx-1"
                placeholder="Phone number..."
                value={phoneNumberParam}
                onChange={(e) => setPhoneNumberParam(e.target.value)}
              />
              <button
                onClick={() => {
                  offset === 0 ? getData() : setOffset(0);
                }}
                className="btn btn-outline-primary "
              >
                Search
              </button>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <td>Phone number</td>
                  <td>Order ID</td>
                  <td>Date</td>
                  <td>Gateway</td>
                  <td>Coin</td>
                  <td>Value received</td>
                  <td>Wallet Address</td>
                  <td>TXID</td>
                  <td>Ex. Rate (NGN/USD)</td>
                  <td>Confirmation time</td>
                  <td>Coin market price</td>
                  <td>Amount($)</td>
                  <td>Amount(₦)</td>
                  <td>Bank name</td>
                  <td>Account number</td>
                  <td>Status</td>
                  <td>ID</td>
                  <td>User ID</td>
                </tr>
              </thead>
              <tbody>
                {data?.map((i) => (
                  <tr>
                    <td>{i.phone_number ?? " - "}</td>
                    <td>{i.index}</td>
                    <td>{moment(i.date).format("YYYY/MM/DD - HH:mm")}</td>
                    <td>{i?.gateway ?? " - "}</td>
                    <td>{i.coin}</td>
                    <td>{i.coin_amount ? i.coin_amount.toFixed(8) : " - "}</td>
                    <td>{truncate(i.address)}</td>

                    <td>
                      {!i.txid ? (
                        " - "
                      ) : (
                        <a
                          href={
                            i.coin === "BTC"
                              ? `https://www.blockchain.com/explorer/transactions/btc/${i.txid}`
                              : `https://tronscan.org/#/transaction/${i.txid}`
                          }
                        >
                          {truncate(i.txid)}
                        </a>
                      )}
                    </td>
                    <td>
                      {!i?.coin_usdt_rate ? " - " : i?.ngn_rate.toFixed(2)}
                    </td>
                    <td>
                      {i.confirmation_date
                        ? moment(i.confirmation_date).format(
                            "YYYY/MM/DD - HH:mm"
                          )
                        : " - "}
                    </td>
                    <td>
                      {!i?.coin_usdt_rate ? 0 : i?.coin_usdt_rate.toFixed(2)}
                    </td>
                    <td>{!i?.usd_amount ? 0 : i?.usd_amount.toFixed(2)}</td>
                    <td>{i.ngn_amount ? i.ngn_amount.toFixed(2) : " - "}</td>
                    <td>{i.account?.bankName ?? " - "}</td>
                    <td>{i.account?.accountNumber ?? " - "}</td>
                    <td>{getStatus(i)}</td>
                    <td>
                      <Link to={`/orders/${i._id}`}>{truncate(i._id)}</Link>
                    </td>
                    <td>
                      <Link to={`/users/${i.user_id}`}>
                        {truncate(i.user_id)}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <ButtonGroup style={{ maxWidth: 300 }} className="mx-auto">
              <button
                className="btn btn-sm btn-outline-secondary"
                disabled={offset <= 0}
                onClick={() => setOffset((prev) => prev - LIMIT)}
              >
                PREVIOUS
              </button>

              <button
                disabled={offset + LIMIT >= totalCount}
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setOffset((prev) => prev + LIMIT)}
              >
                NEXT
              </button>
            </ButtonGroup>
          </>
        )}
      </Card>
    </div>
  );
}
