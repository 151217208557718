import Axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card } from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import toast from "../../components/toast";
export function ManualFund() {
  const [banks, setBanks] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getBanks = () => {
    Axios.get("/banks/get").then((res) => {
      setBanks(res.data.result.banks);
    });
  };

  useEffect(() => {
    getBanks();
  }, []);

  const handleFund = (data) => {
    const { id, ...rest } = data;
    Axios.post(`/admin/payment/process/${id}`, rest)
      .then(() => {
        toast("success", "funded successfully");
        reset();
      })
      .catch(() => {
        toast("error", "operation failed");
      });
  };
  return (
    <div className="page-content">
      <Breadcrumb title="Settings" />
      <Card className="p-5">
        <p className="h5">Manual Fund</p>
        <form
          onSubmit={handleSubmit(handleFund)}
          style={{
            maxWidth: 400,
            margin: "0 auto",
            padding: "0",
            width: "100%",
          }}
        >
          <div className="form-group">
            <label className="form-label">ID</label>
            <input
              className="form-control w-100"
              {...register("id", {
                required: { value: true, message: "Required" },
              })}
              placeholder="ID"
            />
            <small className="text-danger">{errors?.id?.message}</small>
          </div>

          <div className="form-group">
            <label className="form-label">NGN amount</label>
            <input
              className="form-control w-100"
              {...register("ngn_amount", {
                required: { value: true, message: "Required" },
              })}
              placeholder="NGN amount"
              type="number"
            />
            <small className="text-danger">{errors?.ngn_amount?.message}</small>
          </div>

          <div className="form-group">
            <label className="form-label">USD amount</label>
            <input
              className="form-control w-100"
              {...register("usd_amount", {
                required: { value: true, message: "Required" },
              })}
              type="number"
              placeholder="USD amount"
            />
            <small className="text-danger">{errors?.usd_amount?.message}</small>
          </div>

          <div className="form-group">
            <label className="form-label">Bank</label>
            <select
              className="form-control w-100"
              {...register("bank_name", {
                required: { value: true, message: "Required" },
              })}
              placeholder="USD amount"
            >
              {banks?.map((item, index) => (
                <option key={index} value={item.name}>
                  {item?.name}
                </option>
              ))}
            </select>
            <small className="text-danger">{errors?.bank_name?.message}</small>
          </div>

          <div className="form-group">
            <label className="form-label">Bank Number</label>
            <input
              className="form-control w-100"
              {...register("bank_number", {
                required: { value: true, message: "Required" },
              })}
              placeholder="Bank Number..."
            />
            <small className="text-danger">
              {errors?.bank_number?.message}
            </small>
          </div>

          <button className="btn btn-primary w-100">Submit</button>
        </form>
      </Card>
    </div>
  );
}
