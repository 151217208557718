import Axios from "axios";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { ButtonGroup, Card, Modal, Table } from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import toast from "../../components/toast";
import { truncate } from "../../components/utils";
const LIMIT = 100;

export function Wallets() {
  const [createModal, setCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [freezeLoading, setFreezeLoading] = useState(false);
  const [wallets, setWallets] = useState(null);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getWallets = () => {
    setLoading(true);
    Axios.get(`/admin/wallets?limit=${LIMIT}&offset=${offset}`)
      .then((res) => {
        setWallets(res.data.result.result);
        setTotalCount(res.data.result_info.info.total_count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getWallets();
  }, []);

  const onCreate = (data) => {
    setLoading(true);
    Axios.post("/admin/wallet/create", data)
      .then(() => {
        getWallets();
        reset();
        setCreateModal(false);
        toast("success", "Wallet created successfully");
        setLoading(false);
      })
      .catch(() => {
        toast("error", "operation failed");
        setLoading(false);
      });
  };

  const handleFreeze = (id) => {
    setFreezeLoading(id);
    Axios.post(`/admin/wallet/freeze/${id}`)
      .then(() => {
        setFreezeLoading(null);
        toast("success", "Operation was successfull");
        getWallets();
      })
      .catch(() => {
        toast("error", "Operation Failed");
        setFreezeLoading(null);
      });
  };

  const handleAssign = (id) => {
    setFreezeLoading(id);
    Axios.post(`/admin/wallet/free/${id}`)
      .then(() => {
        setFreezeLoading(null);
        toast("success", "Operation was successfull");
        getWallets();
      })
      .catch((e) => {
        toast("error", "Operation Failed");
        console.log(e)
        setFreezeLoading(null);
      });
  };

  const handleDelete = (id) => {
    setFreezeLoading(id);
    Axios.post(`/admin/wallet/delete/${id}`)
      .then(() => {
        setFreezeLoading(null);
        toast("success", "Operation was successfull");
        getWallets();
      })
      .catch(() => {
        toast("error", "Operation Failed");
        setFreezeLoading(null);
      });
  };

  return (
    <>
      <div className="page-content">
        {/* {!!timeLeft && shouldStartTimer.current === true && (
        <Alert color="info">Data will update in {timeLeft} seconds</Alert>
      )} */}
        <Card className="p-3">
          <Breadcrumb
            title={`Wallets ${totalCount ? `(${totalCount})` : ""}`}
          />
          <div>
            <button
              onClick={() => setCreateModal(true)}
              className="btn btn-outline-primary btn-sm mb-2"
            >
              create
            </button>
            {!wallets ? (
              <div className="d-flex justify-content-center p-5">
                <PuffLoader size={100} />
              </div>
            ) : (
              <Table responsive>
                <thead>
                  <td>Address</td>
                  <td>Coin</td>
                  <td>Date</td>
                  <td>Free</td>
                  <td>Payment</td>
                  <td>Freeze</td>
                  <td>Manage</td>
                </thead>
                <tbody>
                  {wallets.map(
                    ({
                      address,
                      coin,
                      date,
                      free,
                      payment_id,
                      freeze,
                      _id,
                    }) => (
                      <tr>
                        <td>{address ?? " - "}</td>
                        <td>{coin ?? " - "}</td>
                        <td>{moment(date).format("YYYY/MM/DD - HH:mm")}</td>
                        <td>
                          <button
                            disabled={freezeLoading === _id}
                            onClick={() => handleAssign(_id)}
                            className={`btn btn-sm btn-outline-primary`}
                          >
                            {freezeLoading
                              ? "Loading..."
                              : free
                              ? "not assigned"
                              : "unassign"}
                          </button>
                        </td>
                        <td>
                          {" "}
                          <Link to={`/order/${payment_id}`}>
                            {payment_id ? truncate(payment_id) : " - "}
                          </Link>
                        </td>
                        <td>
                          <button
                            disabled={freezeLoading === _id}
                            onClick={() => handleFreeze(_id)}
                            className={`btn btn-sm btn-outline-${
                              freeze ? "danger" : "primary"
                            }`}
                          >
                            {freezeLoading
                              ? "Loading..."
                              : freeze
                              ? "activate"
                              : "deactivate"}
                          </button>
                        </td>
                        <td>
                          <button
                            disabled={freezeLoading === _id || !free}
                            onClick={() => handleDelete(_id)}
                            className="btn btn-sm btn-outline-danger"
                          >
                            {freezeLoading ? "Loading..." : "delete"}
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                  <tr></tr>
                </tbody>
              </Table>
            )}
            <ButtonGroup style={{ maxWidth: 300 }} className="mx-auto">
              <button
                className="btn btn-sm btn-outline-secondary"
                disabled={offset <= 0}
                onClick={() => setOffset((prev) => prev - LIMIT)}
              >
                PREVIOUS
              </button>

              <button
                disabled={offset + LIMIT >= totalCount}
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setOffset((prev) => prev + LIMIT)}
              >
                NEXT
              </button>
            </ButtonGroup>
          </div>
        </Card>
      </div>
      <Modal
        isOpen={createModal}
        toggle={() => setCreateModal((prev) => !prev)}
        title="create new wallet"
      >
        <div className="p-3">
          <form onSubmit={handleSubmit(onCreate)}>
            <select className="form-control mb-2" {...register("coin")}>
              <option value="TRON-USDT">TRON-USDT</option>
            </select>
            <input
              className="form-control"
              placeholder="Address..."
              {...register("address", {
                required: {
                  value: true,
                  message: "Address is required!",
                },
              })}
            />
            <input
              className="form-control mt-2"
              type="password"
              placeholder="PIN..."
              {...register("pin", {
                required: {
                  value: true,
                  message: "Pin is required!",
                },
              })}
            />

            <button
              disabled={loading}
              type="submit"
              className="btn btn-primary mt-3"
            >
              {loading ? "sending..." : "Send"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}
