import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Card } from "reactstrap";
import Breadcrumb from "../components/Common/Breadcrumb";
import toast from "../components/toast";
export default function Settings() {
  const [exRate, setExRate] = useState("");
  const [diffRate, setDiffRate] = useState("");
  const [passwd, setPasswd] = useState("");
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [gretingMessage, setGreetingMessage] = useState();
  const getRate = useCallback(() => {
    setLoading(true);
    Axios.get(`/admin/get/rate`)
      .then((res) => {
        setLoading(false);
        setExRate(res.data.result.bitmessa.toString());
        setDiffRate(res.data.result.btc_difference.toString());
        setStatus(res.data.result);
        setGreetingMessage(res.data.result.message);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const updateExChangeRate = useCallback(() => {
    setLoading(true);
    Axios.post(`admin/update/rate/${exRate}`)
      .then(() => {
        toast("success", "exchange rate updated");
        getRate();
      })
      .catch(() => {
        toast("error", "something happened");
      });
  }, [exRate]);

  const updateDiffRate = useCallback(() => {
    setLoading(true);
    Axios.post(`admin/update/btc_rate/${diffRate}`)
      .then(() => {
        toast("success", "diffrence rate updated");
        getRate();
      })
      .catch(() => {
        toast("error", "something happened");
      });
  }, [diffRate]);

  const updatePass= useCallback(() => {
    setLoading(true);
    let adminId = '63afabdc1a3fe0fe2342abdd';
    Axios.post(`admin/admin/password/${adminId}`, { password: passwd, re_password: passwd })
      .then(() => {
        toast("success", "admin password updated");
        getRate();
      })
      .catch(() => {
        toast("error", "something happened");
      });
  }, [passwd]);

  const updatePlatformStatus = (type) => {
    setLoading(true);
    Axios.post(`/admin/activate/${type}`).then(() => {
      toast("success", "site status updated");
      getRate();
    });
  };
  const updateGreetingMessage = (type) => {
    setLoading(true);
    Axios.post(`/admin/set/message`, { message: gretingMessage }).then(() => {
      toast("success", "Greeting Message updated");
      getRate();
    });
  };

  useEffect(() => {
    getRate();
  }, []);
  return (
    <div className="page-content">
      <Breadcrumb title="Settings" />
      <Card className="p-5">
        <p className="h5">Exchange Rate</p>
        <div className="p-5 mx-auto" style={{ width: 500 }}>
          <input
            disabled={loading}
            value={exRate}
            onChange={(e) => setExRate(e.target.value)}
            inputMode="numeric"
            className="form-control mb-2"
          />
          <button
            onClick={updateExChangeRate}
            disabled={loading}
            className="btn btn-outline-primary w-100"
          >
            {loading ? "LOADING..." : "SUBMIT"}
          </button>
        </div>
      </Card>
      <Card className="p-5">
        <p className="h5">BTC Difference</p>
        <div className="p-5 mx-auto" style={{ width: 500 }}>
          <input
            disabled={loading}
            value={diffRate}
            onChange={(e) => setDiffRate(e.target.value)}
            inputMode="numeric"
            className="form-control mb-2"
          />
          <button
            onClick={updateDiffRate}
            disabled={loading}
            className="btn btn-outline-primary w-100"
          >
            {loading ? "LOADING..." : "SUBMIT"}
          </button>
        </div>
      </Card>
      <Card className="p-5">
        <p className="h5">Admin Password</p>
        <div className="p-5 mx-auto" style={{ width: 500 }}>
          <input
            disabled={loading}
            value={passwd}
            onChange={(e) => setPasswd(e.target.value)}
            inputMode="text"
            className="form-control mb-2"
          />
          <button
            onClick={updatePass}
            disabled={loading}
            className="btn btn-outline-primary w-100"
          >
            {loading ? "LOADING..." : "SUBMIT"}
          </button>
        </div>
      </Card>
      <Card className="p-5">
        <p className="h5">Platrom State</p>
        <div
          className=" mx-auto d-flex flex-column mt-3"
          style={{ width: 500 }}
        >
          <button
            className="btn btn-outline-primary mb-3"
            disabled={loading}
            onClick={() => updatePlatformStatus("site")}
          >
            {console.log(status?.site)}
            {!status
              ? "LOADING..."
              : `Website is ${status.site ? "active" : "deactive"}. (click to
            ${status.site ? "deactive" : "active"})`}
          </button>
          <button
            className="btn btn-outline-primary"
            disabled={loading}
            onClick={() => updatePlatformStatus("bot")}
          >
            {!status
              ? "LOADING..."
              : `Bot is ${status.bot ? "active" : "deactive"}. (click to
            ${status.bot ? "deactive" : "active"})`}
          </button>
        </div>
      </Card>
      <Card className="p-5">
        <p className="h5">Greeting Message</p>
        <div className="p-5 mx-auto" style={{ width: 500 }}>
          <textarea
            maxLength={100}
            disabled={loading}
            value={gretingMessage || ""}
            onChange={(e) => setGreetingMessage(e.target.value)}
            className="form-control mb-2"
            rows={6}
          />
          <button
            onClick={updateGreetingMessage}
            disabled={loading}
            className="btn btn-outline-primary w-100"
          >
            {loading ? "LOADING..." : "SUBMIT"}
          </button>
        </div>
      </Card>
    </div>
  );
}
